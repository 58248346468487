<template>
  <tr
    v-if="reservationObj && status == 1"
    @click="openDetails()"
    class="cursor-pointer"
  >
    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{ $date(reservationObj.createdDateTime).format("DD-MM-YYYY HH:mm") }}
      </span>
    </th>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <span
        class="font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{
          reservation
            ? `${reservation?.team?.name}`
            : `${reservationObj?.team?.name}`
        }}
      </span>
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <span
        class="font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{
          reservation
            ? `${reservation?.team?.main_booker_user?.name} ${reservation?.team?.main_booker_user?.surename}`
            : `${reservationObj?.team?.main_booker_user?.name} ${reservationObj?.team?.main_booker_user?.surename}`
        }}
      </span>
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      {{ reservationObj?.team?.main_booker_user?.phone }}
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        p-4
        max-w-3xl
      "
      style="max-width: 200px"
    >
      {{ reservationObj?.remarks }}
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <span
        class="font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{
          reservation
            ? `${reservation?.numberofguests}`
            : `${reservationObj?.numberofguests}`
        }}
      </span>
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <div class="flex items-center">
        <span class="mr-2">{{ percentagePaid }}%</span>
        <div class="relative w-full">
          <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
            <div
              :style="`width: ${percentagePaid}%`"
              class="
                shadow-none
                flex flex-col
                text-center
                whitespace-nowrap
                text-white
                justify-center
              "
              :class="backgroundColorOnPercentage()"
            ></div>
          </div>
        </div>
      </div>
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <div
        v-if="
          payedAmount >=
          reservationObj?.remnant_fee.amount * reservationObj?.numberofguests
        "
      >
        <i class="fas fa-circle text-emerald-500 mr-2"></i> Volledig
      </div>
      <div
        v-else-if="
          payedAmount >
          reservationObj?.deposit_fee.amount * reservationObj?.numberofguests
        "
      >
        <i class="fas fa-circle text-orange-500 mr-2"></i> Restantbetaling
      </div>
      <div
        v-else-if="
          payedAmount ==
          reservationObj?.deposit_fee.amount * reservationObj?.numberofguests
        "
      >
        <i class="fas fa-circle text-orange-500 mr-2"></i> Aanbetaald
      </div>
      <div v-else><i class="fas fa-circle text-red-500 mr-2"></i> Pending</div>
      <!-- <i v-else-if="payedAmount >= reservationObj?.deposit_fee.amount" class="fas fa-circle text-orange-500 mr-2"></i> Aanbetaald -->
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <span
        class="font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{ gender }}
      </span>
    </td>
    <td>
      <ResActionDropDown
        :reservationnumber="reservationObj.reservationnumber"
        @update-reservation="updateReservation"
        @update-status="updateStatus"
        @update-gender="updateGender"
      />
    </td>
  </tr>
  <tr class="w-full border-b-2 border-t-2 border-blueGray-300">
    <td colspan="10" class="px-4">
      <div
        class="
          flex flex-row
          w-full
          flex-wrap
          transition-all
          duration-1000
          overflow-hidden
        "
        :class="showDetails && reservation ? 'pb-4' : 'max-h-0'"
      >
        <div class="w-1/2 pr-4 mb-4">
          <div class="border p-4 h-full">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Team info
            </h6>
            <form
              class="flex flex-wrap justify-end pl-4"
              @submit="ChangeTeamInfoFun($event)"
            >
              <div class="w-full lg:w-4/12 pr-4">
                <div class="relative w-full mb-3">
                  <label
                    class="
                      block
                      uppercase
                      text-blueGray-600 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-password"
                  >
                    Team naam </label
                  ><input
                    type="text"
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      text-blueGray-600
                      bg-white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    name="name"
                    :value="reservationObj?.team?.name"
                  />
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="
                      block
                      uppercase
                      text-blueGray-600 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-password"
                  >
                    Naam aangepast
                  </label>
                  <span>{{
                    reservation?.team?.changedName ? "Ja" : "Nee"
                  }}</span>
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="
                      block
                      uppercase
                      text-blueGray-600 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-password"
                  >
                    Hoofdboeker
                  </label>
                  <select
                    name="newMainbookerId"
                    id="mainBooker"
                    v-model="mainBooker"
                  >
                    <option
                      v-for="member in reservation?.team?.members"
                      :key="member?.id"
                      :value="member?.id"
                    >
                      {{ member?.name }}
                    </option>
                  </select>
                </div>
              </div>
              <button
                class="
                  bg-emerald-500
                  active:bg-emerald-600
                  uppercase
                  text-white
                  font-bold
                  hover:shadow-md
                  shadow
                  text-xs
                  px-4
                  py-2
                  rounded
                  outline-none
                  focus:outline-none
                  sm:mr-2
                  mb-1
                  ease-linear
                  transition-all
                  duration-150
                "
                type="submit"
              >
                Save
              </button>
            </form>
            <div class="w-full flex flex-wrap items-center pt-3">
              <label
                class="
                  w-full
                  block
                  uppercase
                  text-blueGray-600 text-xs
                  font-bold
                  mb-2
                "
                for="grid-password"
              >
                NOG
              </label>
              <div
                class="
                  bg-emerald-500
                  active:bg-emerald-600
                  uppercase
                  text-white
                  font-bold
                  hover:shadow-md
                  shadow
                  text-xs
                  px-4
                  py-2
                  rounded
                  outline-none
                  focus:outline-none
                  ease-linear
                  transition-all
                  duration-150
                  cursor-pointer
                "
                @click="changeNog('-')"
              >
                -
              </div>
              <div class="text-center px-3">{{ nog }}</div>
              <div
                class="
                  bg-emerald-500
                  active:bg-emerald-600
                  uppercase
                  text-white
                  font-bold
                  hover:shadow-md
                  shadow
                  text-xs
                  px-4
                  py-2
                  rounded
                  outline-none
                  focus:outline-none
                  sm:mr-2
                  ease-linear
                  transition-all
                  duration-150
                  mr-16
                  cursor-pointer
                "
                style="margin-right: 30px"
                @click="changeNog('+')"
              >
                +
              </div>
              <div
                class="
                  bg-emerald-500
                  active:bg-emerald-600
                  uppercase
                  text-white
                  font-bold
                  hover:shadow-md
                  shadow
                  text-xs
                  px-4
                  py-2
                  rounded
                  outline-none
                  focus:outline-none
                  sm:ml-2
                  ease-linear
                  transition-all
                  duration-150
                  cursor-pointer
                "
                @click="updateNog()"
              >
                Save
              </div>
            </div>
          </div>
        </div>

        <div class="w-1/2 mb-4">
          <div class="border p-4 h-full">
            <h6
              class="
                text-blueGray-400 text-sm
                mt-3
                mb-6
                font-bold
                uppercase
                mb-4
              "
            >
              Voucher
            </h6>
            <form
              v-if="reservation?.voucher?.guid"
              @submit.prevent="ChangeVoucherFun($event)"
              class="flex flex-wrap justify-end pl-4"
            >
              <div class="w-full lg:w-4/12 pr-4">
                <div class="relative w-full mb-3">
                  <label
                    class="
                      block
                      uppercase
                      text-blueGray-600 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-password"
                  >
                    Voucher GUID
                  </label>
                  <span> {{ reservation?.voucher?.guid }} </span>
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="
                      block
                      uppercase
                      text-blueGray-600 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-password"
                  >
                    Bedrag
                  </label>
                  <input
                    :value="formattedCurrency(reservation?.voucher?.amount)"
                    type="number"
                    name="newVoucherVal"
                    class="
                      w-full
                      border-0
                      px-3
                      py-1
                      placeholder-blueGray-300
                      text-blueGray-600
                      bg-white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none focus:ring
                      w-auto
                      ease-linear
                      transition-all
                      duration-150
                    "
                  />
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="
                      block
                      uppercase
                      text-blueGray-600 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-password"
                  >
                    Aanvangsbedrag
                  </label>
                  <span
                    >&#8364;
                    {{
                      formattedCurrency(reservation?.voucher?.initialAmount)
                    }}</span
                  >
                </div>
              </div>
              <button
                class="
                  bg-emerald-500
                  active:bg-emerald-600
                  uppercase
                  text-white
                  font-bold
                  hover:shadow-md
                  shadow
                  text-xs
                  px-4
                  py-2
                  rounded
                  outline-none
                  focus:outline-none
                  sm:mr-2
                  mb-1
                  ease-linear
                  transition-all
                  duration-150
                "
                type="submit"
              >
                Save
              </button>
            </form>

            <form
              v-else
              @submit.prevent="AddVoucherFun($event)"
              class="flex items-center pl-4"
            >
              <div class="w-full lg:w-4/12 pr-4">
                <div class="relative w-full mb-3">
                  <label
                    class="
                      block
                      uppercase
                      text-blueGray-600 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-password"
                  >
                    Nieuw voucher GUID
                  </label>
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="
                      block
                      uppercase
                      text-blueGray-600 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-password"
                  >
                    Bedrag
                  </label>
                  <input
                    :value="formattedCurrency(reservation?.voucher?.amount)"
                    type="number"
                    name="addVoucherVal"
                    class="
                      w-full
                      border-0
                      px-3
                      py-1
                      placeholder-blueGray-300
                      text-blueGray-600
                      bg-white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none focus:ring
                      w-auto
                      ease-linear
                      transition-all
                      duration-150
                    "
                  />
                </div>
              </div>
              <button
                class="
                  w-auto
                  bg-emerald-500
                  active:bg-emerald-600
                  uppercase
                  text-white
                  font-bold
                  hover:shadow-md
                  shadow
                  text-xs
                  px-4
                  py-2
                  pt-4
                  rounded
                  outline-none
                  focus:outline-none
                  sm:mr-2
                  mb-1
                  ease-linear
                  transition-all
                  duration-150
                "
                type="submit"
              >
                Save
              </button>
            </form>
          </div>
        </div>

        <div class="w-1/2 pr-4 mb-4">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Teamleden
          </h6>
          <div class="w-full pl-4">
            <table class="w-full">
              <thead>
                <tr class="table w-full">
                  <th
                    class="
                      px-6
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Name
                  </th>
                  <th
                    class="
                      px-6
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Tel. nr
                  </th>
                  <th
                    class="
                      px-6
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Activated
                  </th>
                  <th
                    class="
                      px-6
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Pass updated
                  </th>
                </tr>
              </thead>
              <tbody
                class="block w-full overflow-auto border border-t-0"
                style="height: 250px"
              >
                <UserRowActionDropDown
                  v-for="member in reservation?.team?.members"
                  :key="member?.id"
                  :userGuid="member?.guid"
                  :userId="member?.id"
                  :userEmail="member?.email"
                  :userPhone="member?.phone"
                  :reservationnumber="reservationObj.reservationnumber"
                  :teamID="reservation?.team?.id"
                  :userActive="member?.activated_at"
                  @update-reservation="updateReservation"
                >
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{ member?.name }} {{ member?.surename }} ({{
                        member?.id
                      }})
                    </span>
                  </td>
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="ml-3 font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{ member?.phone }}
                    </span>
                  </td>
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{
                        member?.activated_at
                          ? $date(member?.activated_at).format(
                              "DD-MM-YYYY HH:mm"
                            )
                          : ""
                      }}
                    </span>
                  </td>
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="ml-3 font-bold flex"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{
                        $date(member?.password_updatedAt).format(
                          "DD-MM-YYYY HH:mm"
                        )
                      }}
                    </span>
                  </td>
                </UserRowActionDropDown>
              </tbody>
            </table>
          </div>
        </div>

        <div class="w-1/2 mb-4">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Betalingen
          </h6>
          <div class="w-full pl-4">
            <table class="w-full overflow-hidden">
              <thead>
                <tr class="table w-full">
                  <th
                    class="
                      px-3
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    #
                  </th>
                  <th
                    class="
                      px-3
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Gebruiker
                  </th>
                  <th
                    class="
                      px-3
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Bedrag
                  </th>
                  <th
                    class="
                      px-3
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Type
                  </th>
                  <th
                    class="
                      px-3
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Status
                  </th>
                  <th
                    class="
                      px-6
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Split
                  </th>
                  <th
                    class="
                      px-6
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Datum
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="showDetails && reservationPayments"
                class="
                  block
                  w-full
                  overflow-auto
                  border border-t-0
                  overflow-auto
                "
                style="height: 250px"
              >
                <tr
                  v-for="payment in reservationPayments"
                  :key="payment?.id"
                  class="table w-full"
                >
                  <td
                    class="
                      border-t-0
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{ payment?.molliePayment[0] ? payment?.molliePayment[0]?.ref : 'nvt' }}
                    </span>
                  </td>
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{ payerName(payment?.memberID) }}
                    </span>
                  </td>
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{ formattedCurrency(payment?.amount) }}
                    </span>
                  </td>
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{ payment?.type }} {{payment?.voucherGuid ? ' (V)' : ''}}
                    </span>
                  </td>
                  <td
                    class="
                      border-t-0
                      px-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                    "
                  >
                    <span v-if="payment?.molliePayment[0]">
                      <i
                        :class="
                          payment?.molliePayment[0]?.status == 'paid'
                            ? 'text-emerald-500'
                            : payment?.molliePayment[0]?.status == 'open'
                            ? 'text-orange-500'
                            : 'text-red-500'
                        "
                        class="fas fa-circle mr-2"
                      ></i>
                      {{ payment?.molliePayment[0]?.status }}
                    </span>
                    <span v-else-if="payment?.voucherGuid">
                      <i
                        :class="
                         payment?.status ? 'text-emerald-500' : 'text-red-500'
                        "
                        class="fas fa-circle mr-2"
                      ></i>
                      Voucher
                    </span>
                  </td>
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="ml-3 font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{ payment?.splitpayment ? "Ja" : "Nee" }}
                    </span>
                  </td>
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{
                        $date(payment?.createdDateTime).format(
                          "DD-MM-YYYY HH:mm"
                        )
                      }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w-1/2 pr-4">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Tenten
          </h6>
          <div class="w-full pl-4">
            <table class="w-full">
              <thead>
                <tr class="table w-full">
                  <th
                    class="
                      px-6
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Amount
                  </th>
                  <th
                    class="
                      px-6
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Name
                  </th>
                  <th
                    class="
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                      pr-12
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  ></th>
                  <th
                    class="
                      align-middle
                      border border-solid
                      py-3
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    "
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    Price
                  </th>
                </tr>
              </thead>
              <tbody
                class="block w-full overflow-auto border border-t-0"
                style="height: 180px"
              >
                <tr
                  v-for="(tent, index) in reservation?.tent"
                  :key="`tent-${index}`"
                  class="w-full table"
                >
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{ tent.quantity }}
                    </span>
                  </td>
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-4
                      text-left
                    "
                  >
                    <span
                      class="ml-3 font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{ tent.tent_option?.name }} -
                      {{ tent.tent_option?.capacity_text }}
                    </span>
                  </td>
                  <td></td>
                  <td
                    class="
                      border-t-0
                      pr-6
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      py-4
                      pl-0
                      text-left
                    "
                  >
                    <span
                      class="font-bold"
                      :class="[
                        color === 'light' ? 'text-blueGray-600' : 'text-white',
                      ]"
                    >
                      {{ formattedCurrency(tent.tent_option?.price) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w-1/2 pr-4 flex flex-col">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Opmerkingen
          </h6>
          <div class="w-full h-full pl-4 flex flex-col items-end">
            <textarea
              v-model="comment"
              name=""
              id=""
              class="w-full"
              style="height: 80%"
            ></textarea>
            <button
              class="
                bg-emerald-500
                active:bg-emerald-600
                uppercase
                text-white
                font-bold
                hover:shadow-md
                shadow
                text-xs
                px-4
                py-2
                rounded
                outline-none
                focus:outline-none
                sm:mr-2
                mb-1
                ease-linear
                transition-all
                duration-150
                mt-3
              "
              type="button"
              @click="saveComment"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </td>
  </tr>
  <teleport to="#modal">
    <SuccesAlert :show="showAlert" @resetShow="showAlert = false"/>
    <Modal v-if="openModal" :title="modalTitle" :text="modalText" @closeModal="openModal = false" />
  </teleport>
</template>
<script setup>
import formattedCurrency from "@/formattedCurrency.js";
</script>
<script>
import ResActionDropDown from "@/components/Dropdowns/ResActionDropDown.vue";
import UserRowActionDropDown from "@/components/Dropdowns/UserRowActionDropDown.vue";
import Modal from "@/components/Elements/Modal.vue";
import SuccesAlert from '@/components/Elements/SuccesAlert.vue';

import { getReservation } from "@/graphql/reservations/query.js";
import { ChangeComment, ChangeReservationNog } from "@/graphql/reservations/mutations.js";
import { ChangeTeamInfo } from "@/graphql/team/mutations.js";
import { ChangeVoucher } from "@/graphql/voucher/mutations.js";
import { AddVoucher } from "@/graphql/voucher/mutations.js";

export default {
  components: {
    ResActionDropDown,
    UserRowActionDropDown,
    Modal,
    SuccesAlert
  },
  props: {
    reservationObj: {
      default: false,
    },
    totalRes: {
      default: 0,
      type: Number,
    },
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      showDetails: false,
      dropdownPopoverShow: false,
      amountToPay: 0,
      payedAmount: 0,
      reservationPayments: [],
      mainBooker: this.reservationObj?.team?.main_booker_user?.id,
      status: 1,
      gender: this.reservationObj?.genderOfTeam,
      comment: this.reservationObj?.internal_remarks,
      nog: this.reservationObj.numberofguests,
      openModal: false,
      modalTitle: '',
      modalText: '',
      showAlert: false
    };
  },
  apollo: {
    reservation() {
      return {
        query: getReservation,
        variables() {
          return {
            reservationnumber: this.reservationObj?.reservationnumber,
          };
        },
        skip: () =>
          !this.reservationObj?.reservationnumber || !this.showDetails,
        result({ data }) {
          this.reservationPayments = data?.reservation?.reservationPayments;
        },
      };
    },
  },
  mounted() {
    this.amountToPay =
      this.reservationObj?.numberofguests *
      this.reservationObj?.remnant_fee.amount;
    for (
      let index = 0;
      index < this.reservationObj?.reservationPayments?.length;
      index++
    ) {
      if (
        this.reservationObj?.reservationPayments[index].status === 1 &&
        this.reservationObj?.reservationPayments[index].type !== "tent"
      ) {
        this.payedAmount +=
          this.reservationObj?.reservationPayments[index].amount;
      }
    }
  },
  computed: {
    percentagePaid() {
      return Math.floor((this.payedAmount / this.amountToPay) * 100);
    },
    formattedCurrency(amount) {
      return formattedCurrency(amount);
    },
  },
  methods: {
    async updateNog() {
      try {
        const res = await this.$apollo.mutate({
          mutation: ChangeReservationNog,
          variables: {
            reservationnumber: this.reservationObj?.reservationnumber,
            nog: this.nog
          },
        });

        if (res?.data?.ChangeReservationNog.status == 200) {
          this.updateReservation();
          this.showAlert = true;
        } else {
          this.modalTitle = 'Er ging iets mis.'
          this.modalText = res?.data?.ChangeReservationNog.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }

    },
    changeNog(operator) {
      if (operator == "+") {
        this.nog++;
      } else {
        this.nog--;
      }
    },
    updateReservation() {
      this.$apollo.queries.reservation.refetch();
    },
    updateStatus() {
      this.status = 2;
    },
    updateGender(gender) {
      this.gender = gender;
    },
    backgroundColorOnPercentage() {
      if (
        this.payedAmount >=
        (this.reservationObj?.remnant_fee.amount -
          this.reservationObj?.deposit_fee.amount) *
          this.reservationObj?.numberofguests +
          this.reservationObj?.deposit_fee.amount *
            this.reservationObj?.numberofguests
      ) {
        return "bg-emerald-500";
      } else if (
        this.payedAmount >=
        this.reservationObj?.deposit_fee.amount *
          this.reservationObj?.numberofguests
      ) {
        return "bg-orange-500";
      } else {
        return "bg-red-500";
      }
    },
    openDetails() {
      this.$apollo.queries.reservation.refetch();
      if (!this.showDetails) {
        this.showDetails = true;
      } else {
        this.showDetails = false;
      }
    },
    payerName(id) {
      if (!id) return;
      const member = this.reservation?.team?.members?.find(
        (member) => member.id === id
      );

      if(!member) return '';
      return member.name;
    },
    async ChangeTeamInfoFun(e) {
      let input = {
        teamGuid: this.reservationObj?.team?.guid,
        reservationnumber: this.reservationObj?.reservationnumber,
      };
      e.preventDefault();
      for (let i = 0; i < e.target.length; i++) {
        const element = e.target[i];
        if (element.tagName !== "BUTTON") {
          input[element.getAttribute("name")] = element.value;
        }
      }

      try {
        const res = await this.$apollo.mutate({
          mutation: ChangeTeamInfo,
          variables: {
            input,
          },
        });
        if (res?.data?.ChangeTeamInfo.status == 200) {
          this.updateReservation();
          this.showAlert = true;
        } else {
          this.modalTitle = 'Er ging iets mis.'
          this.modalText = res?.data?.ChangeTeamInfo.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async ChangeVoucherFun(e) {
      let input = {
        voucherGuid: this.reservation?.voucher?.guid,
        newAmount: parseInt(e.target.newVoucherVal.value),
      };

      e.preventDefault();

      try {
        const res = await this.$apollo.mutate({
          mutation: ChangeVoucher,
          variables: {
            input,
          },
        });

        if (res?.data?.ChangeVoucher.status == 200) {
          this.updateReservation();
          this.showAlert = true;
        } else {
          this.modalTitle = 'Er ging iets mis.'
          this.modalText = res?.data?.ChangeVoucher.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async AddVoucherFun(e) {
      let input = {
        amount: parseInt(e.target.addVoucherVal.value),
        reservationnumber: this.reservationObj?.reservationnumber,
      };

      e.preventDefault();

      try {
        const res = await this.$apollo.mutate({
          mutation: AddVoucher,
          variables: {
            input,
          },
        });

        if (res?.data?.AddVoucher.status == 200) {
          this.updateReservation();
          this.showAlert = true;
        } else {
          this.modalTitle = 'Er ging iets mis.'
          this.modalText = res?.data?.AddVoucher.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async saveComment() {
      const input = {
        comment: this.comment,
        reservationnumber: this.reservationObj?.reservationnumber,
      };

      try {
        const res = await this.$apollo.mutate({
          mutation: ChangeComment,
          variables: {
            input,
          },
        });

        if (res?.data?.ChangeComment.status == 200) {
          this.updateReservation();
          this.showAlert = true;
        } else {
          this.modalTitle = 'Er ging iets mis.'
          this.modalText = res?.data?.ChangeComment.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>


<style scoped>
thead,
tbody tr {
  table-layout: fixed; /* even columns width , fix width of table too*/
}
</style>
